<template>
  <b-card
    no-body
    class="card-apply-job m-0"
  >
    <b-card-body>

      <b-row align-v="center" align-h="center" class="mb-1">
        <b-avatar rounded
          size="100px"
          :src="`${goatData.photo}`"
        />
      </b-row>

      <h5 class="apply-job-title text-center mb-1">
        {{ goatData.animal_name }}
      </h5>

      <b-card-text class="text-center mb-3">
        <b-badge
          pill
          variant="light-primary"
        >
        {{ goatData.age }}
        </b-badge>
      </b-card-text>  

      <b-row class="pr-1 pl-1 mb-1">
        <b-col>
          <b-row>
            <b-col cols="4" class="mr-0 ml-0 p-0">
              <b-avatar size="38px" rounded variant="light-primary">
                <feather-icon icon="CheckIcon" />
              </b-avatar>
            </b-col>
            <b-col cols="8" class="mr-0 ml-0 p-0">
              <h5>{{ goatData.animal_status_out }}</h5>     
              <p>{{ goatData.animal_status }}</p>
            </b-col>
          </b-row>
        </b-col>

        <b-col>
          <b-row>
            <b-col cols="4" class="mr-0 ml-0 p-0">
              <b-avatar size="38px" rounded variant="light-primary">
                <feather-icon icon="BriefcaseIcon" />
              </b-avatar>
            </b-col>
            <b-col cols="8" class="mr-0 ml-0 p-0">
              <h5>{{ goatData.animal_type }}</h5>     
              <p>Status</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <b-card-text>
            <h5>Profile</h5>
            <hr/>
          </b-card-text>
          
          <b-row class="pl-1">
            <h5>Status Genetik :</h5>
            <p class="ml-1">
              {{ goatData.genetic_status }}
            </p>
          </b-row>
          
          <b-row class="pl-1">
            <h5>Jenis Kelamin :</h5>
            <p class="ml-1">
              {{ goatData.gender }}
            </p>
          </b-row>
          
          <b-row class="pl-1">
            <h5>Berat :</h5>
            <p class="pl-1">
              {{ goatData.current_weight }}
            </p>
          </b-row>
          
          <b-row class="pl-1">
            <h5>Kandang :</h5>
            <p class="pl-1">
              {{ goatData.pen_name }}
            </p>
          </b-row>
          
          <b-row class="pl-1">
            <h5>Terakhir Diperbaruhi</h5>
            <p class="pl-1">
              {{ goatData.last_updated }}
            </p>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <b-col>
          <b-card-text>
            <h5>Device</h5>
            <hr/>
          </b-card-text>
          
          <b-row class="pl-1">
            <h5>Device ID</h5>
            <p class="pl-1">
              {{ goatData.device_id }}
            </p>
          </b-row>
          
          <b-row class="pl-1">
            <h5>Nama Device</h5>
            <p class="pl-1">
              {{ goatData.device_name }}
            </p>
          </b-row>
          
          <b-row class="pl-1">
            <h5>Status Baterai</h5>
            <p class="pl-1">
              {{ goatData.device_battery_status }}
            </p>
          </b-row>
          
        </b-col>
      </b-row>

      <!--/ payment  -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        @click="$emit('update:is-add-new-goat-sidebar-active', true)"
      >
        Edit Info
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewGoatSidebarActive',
    event: 'update:is-add-new-goat-sidebar-active',
  },
  props: {
    isAddNewGoatSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data(){
    return{
      goatData: null,
    }
  },
  mounted(){
    this.$http.get('/animals/' + this.$route.params.id + '/detail' ).then(response => {
      this.goatData = response.data.data
      
    })
  },
}
</script>
